
@import './fonts.scss';

$border-radius: .20rem !default;
$border-radius-sm: .17rem !default;
$enable-caret: false;

$headings-font-family: 'Prompt', sans-serif;
$font-family: 'Roboto', sans-serif;
$font-family-sans-serif: $font-family;

$primary: #85CD6F;
$success: $primary;
$secondary: #e0e0e0;
$border-color: #eaedf3;
$body-color: #3e3f42;
$body-bg: #fbfbfd;

// company_sign color
$format-icon-bg-light-gray: lighten($secondary, 5%);
$mini-market: $primary;
$supermarket: lighten(#ffc107, 25%);

@import '~bootstrap/scss/bootstrap';

// Ag Grid restyle
$default-background: white;
$odd-row-background-color: $default-background;
$header-background-color: $body-bg;

$active: $primary;

$alt-background: lighten($primary, 50%);
$selected-color: lighten($primary, 35%);

$header-height: 44px;
$row-height: 44px;
$icons-path: '~ag-grid-community/src/styles/ag-theme-balham/icons/';

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss';

.py-75 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.btn-success {
    color: $white;
 &:hover, &:focus {
     color: $white;

 }
}
.btn-primary {
    color: $white;
    &:hover, &:focus{
        color: $white;
    }
}

.dot {
    height: 1rem;
    width: 1rem;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
}

.bg-vigie-grey {
    background-color: #34384f;
}

html, body, #app {
    height: 100vh;
}

body {
    font-family: $font-family;
}

.right-sidebar {
    overflow: hidden;
    background: white;
    display: flex;
    overflow-y: scroll;
    border-left: 1px solid $border-color;
}

main {
    height: calc(100% - 65px);
}

.baseline {
    color: $primary;
    font-size: 22px;
    font-family: $font-family;
    font-weight: 300;
}

.entity-info {
    font-family: $font-family;
    font-weight: 500;
    padding: 0 30px;

    dt {
        font-weight: 500;
        color: gray;
        font-size: 13px;
    }

    dd {
        font-size: 15px;
        font-family: $font-family;
        font-weight: 500;
    }

    .d-table-cell {
        padding: 5px 10px 5px 0;
    }

    h2 {
        font-weight: 700;
        font-size: 20px;
    }

    .entity-address {
        font-size: 14px;
        display: block;
    }
}

.vtc {
    height: 80px;

    .stroke {
        stroke-width: 2;
    }

    .fill {
        opacity: 0.2;
    }

    .active-line {
        stroke: rgba(0, 0, 0, 0.2);
    }

    .point {
        display: none;
    }

    .point.is-active {
        display: block;
    }
}

.curve-turnover {
    .stroke {
        stroke: #39af77;
    }

    .fill {
        fill: #39af77;
    }

    .point {
        fill: #39af77;
        stroke: #39af77;
    }
}

.navbar {
    font-family: $font-family;

    input {
        font-family: $font-family !important;
        font-weight: 300 !important;
    }

    .right-menu {
        font-family: $font-family;
        font-weight: 300 !important;

        .dropdown-toggle {
            padding: 0;
        }
    }

    .header-btns .btn {
        padding: 0.35rem 0.55rem;
    }
}

.shared-view-sidebar {
    background-color: $primary;
    cursor: pointer;
    display: flex;
}

.input-group-text.search-bar-icon {
    background: white;
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.grid-overlay {
    position: absolute;
    z-index: 900;
}

.animate-rotate-360 {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#global-search {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
    padding-left: 0px;

    &:focus {
        box-shadow: none;
        border-color: $input-border-color;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}


.view-selector {
    label {
        cursor: pointer;
        stroke: $body-color !important;

        &.active {
            stroke: $white !important;
            background-color: $primary !important;
            color: #fff !important;
            border-color: $primary !important;
            opacity: 1;
        }

        //&:disabled, &.disabled {
        //    background-color: $primary;
        //    color: #fff;
        //    border-color: $primary;
        //    opacity: 1;
        //}

        box-shadow: none !important;
    }
}

#header-dropdown-right button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center !important;

    &:focus {
        box-shadow: none;
    }
}

input.no-outline, button.no-outline {
    outline: 0 !important;
    box-shadow: none !important;
}

.user-filters-menu {
    width: 16rem;
}

.entity-profile {
    min-width: 470px;
}
.entities-map {
    display: flex;
    min-width: 200px;


    .top-right-anchor {
        position: absolute;
        z-index: 500;
        left: 10px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        width: 34px;

        button.top-right-anchor__action, &__action button {
            padding: 6px 10px;
            line-height: 0;
            vertical-align: middle;

            &.btn-secondary {
                background-color: #fff;
            }
        }
        .marker-resizer {
            .dropdown-menu {
                padding: 20px;
                width: 222px;
            }
        }

        .style-switcher{
            &__selector {
            color: #495057;
            background-color: #fff;
            }
        }

        &__icon {
            width: 16px;
        }


    }

    .avatar {
        background-color: #fff;
        position: absolute;

        &.octagon {
            clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);

            &:before {
                content: '';
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                background: white;
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
            }
        }

        img {
            z-index: 300;
        }
    }
}



.avatar {
    &.cash-and-carry {
        background-color: $format-icon-bg-light-gray;
    }

    &.mini-market {
        background-color: $mini-market;
    }

    &.supermarket {
        background-color: $supermarket;
    }
}


.filter-reset {
    cursor: pointer;
}

.rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.map-style-list-container {
    .dropdown-item {
        padding: 0.25rem 1rem;
    }
}

.ag-theme-balham.ag-theme-vigie {
    font-family: $font-family;
    .ag-header-cell-menu-button {
        float: left;
        opacity: 1 !important;
        margin-right: 0.25rem;
    }

    .footer-function-cell-active {
        background-color: $selected-color;
    }

    .ag-side-bar {
        background-color: transparent;

        .ag-icon-columns {
            background: url('./../../public/images/icons/grid/columns.svg')
        }


        .ag-icon-filter {
            background: url('./../../public/images/icons/grid/filter.svg')
        }


        .ag-side-button button {
            color: white;
            background: #85CD6F;
            margin-bottom: 0.5rem;
            border-top-left-radius: .25rem!important;
            border-bottom-left-radius: .25rem!important;
        }
    }
    .ag-floating-bottom {
        overflow: visible !important;
        border-top: 2px solid #eaedf3;
    }

    .ag-floating-bottom-viewport {
        overflow: visible;

        .ag-cell {
            overflow: visible;
        }

        .ag-cell-focus {
            border: none;
        }

        .dropdown-aggregate-functions {
            .dropdown-menu {
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
            }

            &:hover .hidden-info, &.show .hidden-info {
                opacity: 1 !important;
            }

            .dropdown-toggle {
                background-color: transparent;
                border: none;
                padding-right: 0;

                &:focus {
                    box-shadow: none;
                }
            }

            .dropdown-item {
                padding: 0 1rem;
                font-size: 0.8rem;
            }
        }
    }

    .ag-side-bar {
        border-right: 1px solid #b7b7b8;
    }

    .ag-row-selected .ag-cell {
        background-color: $selected-color;
    }

    .ag-theme-balham .ag-row:not(.ag-row-first) {
        border-width: 0;
    }

    .ag-header-cell-text {
        text-transform: uppercase;
    }

    .ag-header {
        font-weight: 400;
    }

    .ag-cell {
        text-transform: capitalize;
        border-bottom: 1px solid $border-color;

        &:hover button.open-entity-profile {
            opacity: 1
        }
    }

    .ag-row {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.2px;
        &:hover button.open-entity-profile {
            display: inline;
        }
    }

    button.open-entity-profile {
        z-index: 500;
        color: $body-color;
        background-color: $border-color;
        opacity: 0.7;
        border-radius: 50%;
        border: 1px solid darken($border-color, 18%);
        margin-top: -2px;
        display: none;
        transform: scale(0.7);
        width: 44px;
    }

    .ag-floating-filter-input, .ag-filter-filter {
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #8cdb97;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(55, 177, 72, 0.25);
        }
    }

    .ag-input-text-wrapper.ag-filter-header-container {
        height: 38px;
    }

    .commercial-name {
        cursor: pointer;
    }

    .format-avatar {
        background-color: #dddddd;
        width: 38px;
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        line-height: normal;
        padding: .75rem 0;
    }

    .company-group-avatar {
        width: 34px;
        padding: 8px 0;
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: normal;
        margin: auto;
    }

    .region-flag-avatar {
        line-height: normal;

        span {
            background-color: #dddddd;
            width: 33px;
            height: 22px;
            line-height: 20px;
        }
    }
}


.filter-popover {
    max-height: 200px;
    overflow: auto;
}


