



















































































































































































































































































div#map {
  display: block;
  height: 162px;
  width: 162px;
}

.close-sidebar {
  position: absolute;
  z-index: 500;
  margin-top: 13px;
  margin-left: 13px;
  font-size: 26px;
  color: white;
  font-weight: 800;
  background-color: #85CD6F;
  opacity: 1;
  border-radius: 4px;
  padding: 0px 6px;
  border: 1px solid #eaedf3;;

  &:hover {
    opacity: 1 !important;
  }
}

.open-gallery {
  position: absolute;
  z-index: 500;
  width: 41px;
  font-size: 26px;
  color: white;
  font-weight: 800;
  background-color: #292929;
  opacity: 0.8;
  border-radius: 50%;
  border: none;
  right: 0;
  bottom: 0;
  margin: 0 14px 14px 0;

  &:hover {
    opacity: 1
  }

  .feather {
    margin-top: -6px;
  }
}

.entity-image {
  height: 288px;
  background-color: #fbfbfd;
  border-bottom: 1px solid rgba(206, 212, 218, 0.49);

  img {
    background-size: cover;
  }
}
